import React from 'react';
import PropTypes from 'prop-types';

import styles from './App.module.scss';

function AppLayout({ children }) {
  return <div className={styles.content}>{children}</div>;
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export function WithAppLayout(Component) {
  return function AppLayoutHOC(props) {
    return (
      <AppLayout>
        <Component {...props} />
      </AppLayout>
    );
  };
}

export default AppLayout;
